import React from 'react';
import { useMediaQuery } from 'react-responsive';

const Hydro = () => {
    const isMinWidth = useMediaQuery({ query: '(min-width: 768px)' });
    return (
        <>
            <div className="icons-container scale-icons">
                <img src="/images/worklines/hidro-icons.png" alt="CCE - Iconos de hidrocarburos" />
            </div>
            <div className="p-container" style={{ marginTop: -10 }}>
                <p>
                    <b>
                        Buscamos fortalecer el sector de hidrocarburos como un eje estratégico
                        para el desarrollo económico y social de México.
                    </b>
                </p>
            </div>
            <div className="p-container">
                <p>
                    La iniciativa privada es un aliado para complementar los esfuerzos que realiza
                    el Gobierno encaminado a:
                </p>
            </div>
            <div className="article-row p-last">
                <div className="p-container" style={{ width: isMinWidth ? '22%' : '100%' }}>
                    <p>Fortalecer la seguridad energética al incrementar las reservas</p>
                </div>
                <div className="p-container" style={{ width: isMinWidth ? '16%' : '100%' }}>
                    <p>Desarrollar los campos descubiertos</p>
                </div>
                <div className="p-container" style={{ width: isMinWidth ? '16%' : '100%' }}>
                    <p>Reparación de pozos</p>
                </div>
                <div className="p-container" style={{ width: isMinWidth ? '22%' : '100%' }}>
                    <p>
                        Tener el máximo de recuperación de los campos maduros implementando
                        tecnología de punta
                    </p>
                </div>
                <div className="p-container" style={{ width: isMinWidth ? '18%' : '100%' }}>
                    <p>Colaborar en la exploración de nuevos campos</p>
                </div>
            </div>
            <div className="images-divider">
                <img style={{ width: '40%' }} src="/images/worklines/hidro-imagen1.png" alt="CCE - hidro divider" />
                <img style={{ width: isMinWidth ? '55px' : '30px' }} src="/images/worklines/gas-separator.png" alt="CCE - hidro divider" />
                <img style={{ width: '40%' }} src="/images/worklines/hidro-imagen2.png" alt="CCE - hidro divider" />
            </div>
            <h2>Producción de Miles de Barriles Diarios (Mbd) Abril, 2021</h2>
            <h3>Producción nacional</h3>
            <table className="workline-table">
                <tr>
                    <td>
                        Petróleo
                    </td>
                    <td>
                        1,695.2 Mbd
                    </td>
                </tr>
                <tr>
                    <td>
                        Condensado
                    </td>
                    <td>
                        75.5 Mbd
                    </td>
                </tr>
            </table>
            <p className="p-last" style={{ textAlign: 'center', fontSize: 18 }}>Existen 7,068 pozos operando</p>
            <h3>Pozos</h3>
            <table className="workline-table">
                <tr>
                    <td>Total de estudios</td>
                    <td>55</td>
                </tr>
                <tr>
                    <td>En desarrollo</td>
                    <td>11</td>
                </tr>
                <tr>
                    <td>Terminados</td>
                    <td>44</td>
                </tr>
                <tr>
                    <td>Padrón ARES</td>
                    <td>98</td>
                </tr>
                <tr>
                    <td>Empresas con autorizaciones vigentes</td>
                    <td>17</td>
                </tr>
            </table>
            <p>Fuente: Comisión Nacional de Información de Hidrocarburos</p>
            <p className="p-last" style={{ overflow: 'hidden' }}>
                <a
                    href="https://hidrocarburos.gob.mx/estad%C3%ADsticas/"
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                >
                    https://hidrocarburos.gob.mx/estad%C3%ADsticas/
                </a>
            </p>
            <h2>Reservas y Recursos de Hidrocarburos 2019</h2>
            <h3>Reservas</h3>
            <table className="workline-table">
                <tr>
                    <td>1P (Probadas)</td>
                    <td>25.1 MMMbpce</td>
                </tr>
                <tr>
                    <td>2P (Probadas + Probables)</td>
                    <td>15.8 MMMbpce</td>
                </tr>
                <tr>
                    <td>3P (Probadas + Probables + Posibles)</td>
                    <td>7.9 MMMbpce</td>
                </tr>
            </table>
            <h3>Recursos</h3>
            <table className="workline-table">
                <tr>
                    <td>Total</td>
                    <td>112.9 MMMbpce</td>
                </tr>
                <tr>
                    <td>Convencional </td>
                    <td>48.7 MMMbpce</td>
                </tr>
                <tr>
                    <td>No Convencional</td>
                    <td>64.2 MMMbpce</td>
                </tr>
            </table>
            <p className="p-last">
                * MMMbpce = Miles de millones de barriles de petróleo crudo equivalente.
            </p>
        </>
    );
};

export default Hydro;
