import React from 'react';
import { Helmet } from 'react-helmet';

import Header from '../components/common/Header';
import Footer from '../components/home/MainFooter';
import SubHeader from '../components/common/SubHeader';

import WorklinesCmp from '../components/worklines';

const Worklines = () => (
    <>
        <Helmet>
            <title>Comisión de Energía del CCE | Lineas de trabajo</title>
            <meta name="description" content="Sabemos que el sector energético es diverso. Por ello en la Comisión de Energía del CCE enfocamos nuestros esfuerzos en varios rubros de trabajo. Conócelos." />
        </Helmet>
        <main style={{ overflow: 'auto ' }}>
            <Header section="worklines" />
            <SubHeader title="Lineas de trabajo" />
            <WorklinesCmp />
            <Footer />
        </main>
    </>
);

export default Worklines;
