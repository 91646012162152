import { css } from '@emotion/core';

export default css({
    width: '100%',
    background: '#f4f7f8',
    padding: '50px 0px',
    '& .worklines-menu': {
        maxWidth: 1000,
        margin: '0px auto',
        display: 'flex',
        flexDirection: 'column',
        '& > div': {
            width: '100%',
            display: 'flex',
        },
        '& .workline-img, .workline-selected': {
            width: '50%',
            height: 170,
            textDecoration: 'none',
            transition: 'all ease 0.5s',
            '& > div': {
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& > p': {
                    fontSize: 18,
                    fontWeight: 500,
                    textTransform: 'uppercase',
                    color: '#FFF',
                }
            }
        },
        '& .workline-selected': {
            borderRadius: 2,
            transform: 'scale(1.02)',
            zIndex: 2,
            '& > div > p': {
                padding: '40px 5px',
                border: '4px solid #FFF'
            }
        }
    },
    '& > article': {
        maxWidth: 1000,
        margin: '0px auto',
        padding: '45px 40px',
        boxSizing: 'border-box',
        background: '#fff',
        boxShadow: '0 1px 1px rgba(0,0,0,0.12),0 0px 1px rgba(0,0,0,0.24)',
        '& .p-container': {
            marginBottom: 35,
            padding: '30px',
            boxSizing: 'border-box',
            border: '1px dashed #f5333f',
            borderRadius: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& p': {
                fontSize: 18,
                fontWeight: 300,
                textAlign: 'center',
                lineHeight: '22px',
                '& > b': {
                    fontWeight: 'bold'
                }
            },
        },
        '& .icons-container': {
            width: '60%',
            margin: '0px auto 30px',
            '& > img': {
                width: '100%'
            }
        },
        '& .scale-icons': {
            transform: 'scale(0.6)'
        },
        '& .images-divider': {
            width: '100%',
            margin: '0px auto 45px',
            display: 'flex',
            justifyContent: 'space-between'
        },
        '& .article-row': {
            display: 'flex',
            flexDirection: 'column',
        },
        '& .p-last': {
            marginBottom: 45,
        },
        '& h2': {
            fontSize: 26,
            color: '#1f300d',
            textAlign: 'center',
            padding: 25,
        },
        '& h3': {
            fontSize: 22,
            color: '#1f300d',
            textAlign: 'center',
            padding: 15,
            marginBottom: 25,
        },
        '& .title-margin': {
            marginBottom: 25,
        },
        '& a': {
            color: '#1f300d',
            textDecoration: 'none'
        },
        '& .workline-example': {
            display: 'block!important',
            margin: '0px auto 45px',
            width: '100%!important',
            '& img': {
                objectFit: 'contain!important'
            }
        },
        '& .workline-table': {
            width: '100%',
            maxWidth: 797,
            margin: '0px auto 25px',
            boxShadow: '0 1px 1px rgba(0,0,0,0.12),0 0px 1px rgba(0,0,0,0.24)',
            '& > tr': {
                fontSize: 18,
                '& th, td': {
                    textAlign: 'center',
                    border: '1px solid rgba(0,0,0,0.12)',
                    padding: 15,
                },
                '& td:first-of-type': {
                    textAlign: 'left'
                },
                '& .td-sep': {
                    background: '#f4f4f8'
                },
                '& .theader': {
                    color: '#FFF',
                    background: '#f5333f'
                }
            }
        }
    },
    '@media only screen and (max-width: 767px)': {
        '& .workline-example': {
            height: '180px!important'
        },
    },
    '@media only screen and (min-width: 768px)': {
        '& .worklines-menu': {
            flexDirection: 'row',
            '& > div': {
                width: '50%'
            },
            '& .workline-img, .workline-selected': {
                height: 360,
            }
        },
        '& > article': {
            '& .article-row': {
                flexDirection: 'row',
                justifyContent: 'space-between'
            },
        }
    },
    '@media only screen and (min-width: 1024px)': {
        '& .worklines-menu': {
            '& .workline-img, .workline-selected': {
                '& > div > p': {
                    fontSize: 22
                }
            }
        },
        '& > article': {
            padding: '45px 105px',
        },
        '& .workline-example': {
            '& img': {
                objectFit: 'cover'
            }
        },
    }
});
