import React from 'react';
import { useMediaQuery } from 'react-responsive';

const Gas = () => {
    const isMinWidth = useMediaQuery({ query: '(min-width: 768px)' });
    return (
        <>
            <div className="icons-container">
                <img src="/images/worklines/gas-icons.png" alt="CCE - Iconos de gas" />
            </div>
            <div className="p-container">
                <p>
                    Impulsamos el desarrollo de
                    <b> infraestructura en transportación, almacenamiento, distribución y
                        comercialización del Gas Natural
                    </b> con el objetivo de:
                </p>
            </div>
            <div className="article-row p-last">
                <div className="p-container" style={{ width: isMinWidth ? '47%' : '100%' }}>
                    <p>
                        Mejorar la cobertura y confiabilidad del sistema para contar con un
                        suministro generalizado que permita llevarlo a todos los rincones del país.
                    </p>
                </div>
                <div className="p-container" style={{ width: isMinWidth ? '47%' : '100%' }}>
                    <p>
                        Impulsar el uso del gas natural como el combustible más seguro, económico
                        y amigable con el medio ambiente del mercado.
                    </p>
                </div>
            </div>
            <div className="images-divider">
                <img style={{ width: '40%' }} src="/images/worklines/gas-imagen1.png" alt="CCE - gas divider" />
                <img style={{ width: isMinWidth ? '55px' : '30px' }} src="/images/worklines/gas-separator.png" alt="CCE - gas divider" />
                <img style={{ width: '40%' }} src="/images/worklines/gas-imagen2.png" alt="CCE - gas divider" />
            </div>
            <h3>Beneficios del Gas Natural en la economía</h3>
            <div className="article-row p-last">
                <div className="p-container" style={{ width: isMinWidth ? '22%' : '100%' }}>
                    <p>
                        Estados con
                        <b> PIB per cápita hasta 50% más alto</b> que aquellos que no cuenten
                        con él
                    </p>
                </div>
                <div className="p-container" style={{ width: isMinWidth ? '22%' : '100%' }}>
                    <p>
                        Desarrollo de <b>diferentes industrias</b>
                    </p>
                </div>
                <div className="p-container" style={{ width: isMinWidth ? '22%' : '100%' }}>
                    <p>
                        <b>Generación de empleos</b> con efecto multiplicador (1:5)
                    </p>
                </div>
                <div className="p-container" style={{ width: isMinWidth ? '22%' : '100%' }}>
                    <p>
                        Ahorro para las familias <b>hasta 30% respecto al gas LP</b>
                    </p>
                </div>
            </div>
            <div className="p-container">
                <p>
                    En el país se cuenta con <b>17,210 kilómetros de gasoductos</b> de transporte
                    concluidos, pero falta infraestructura en siete estados, principalmente en el
                    sur y sureste del país.
                </p>
            </div>
            <div className="p-container">
                <p>
                    <b>México es uno de los 10 países con mayores reservas de gas en el mundo, </b>
                    geográficamente se ubica en la región del mundo con el gas más barato
                    de Norteamérica; sin embargo, importa más del 80% de su consumo de gas natural.
                </p>
            </div>
            <div className="p-container p-last">
                <p>
                    <b>
                        Esto representa un problema de seguridad energética para México ya que
                        solo cuenta con dos días de reservas de gas.
                    </b>
                </p>
            </div>
        </>
    );
};

export default Gas;
