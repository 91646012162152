import React from 'react';
import { useMediaQuery } from 'react-responsive';

const Petroil = () => {
    const isMinWidth = useMediaQuery({ query: '(min-width: 768px)' });
    return (
        <>
            <div className="icons-container">
                <img src="/images/worklines/petr-icons.png" alt="CCE - Iconos de petrolíferos" />
            </div>
            <div className="p-container">
                <p>
                    <b>
                        Buscamos potenciar la inversión en infraestructura desde el origen,
                        transporte, almacenamiento, distribución y comercialización, para
                        suministrar la demanda de petrolíferos a los usuarios y que cuenten
                        con mayor acceso, menores costos logísticos y mejor valor de compra.
                    </b>
                </p>
            </div>
            <div className="p-container">
                <p>
                    Acompañar las acciones del gobierno para aumentar la seguridad energética
                    y garantizar el suministro de combustibles a todos los mexicanos.
                </p>
            </div>
            <div className="images-divider">
                <img style={{ width: '40%' }} src="/images/worklines/petr-imagen1.png" alt="CCE - petr divider" />
                <img style={{ width: isMinWidth ? '55px' : '30px' }} src="/images/worklines/gas-separator.png" alt="CCE - petr divider" />
                <img style={{ width: '40%' }} src="/images/worklines/petr-imagen2.png" alt="CCE - petr divider" />
            </div>
            <div className="p-container">
                <p>
                    <b>
                        Uno de los retos más importantes para el país es incrementar la capacidad
                        del sistema nacional de refinación y ampliar los días de almacenamiento.
                    </b>
                </p>
            </div>
            <h3>
                El almacenamiento de petrolíferos cumple dos funciones primordiales:
            </h3>
            <div className="article-row p-last">
                <div className="p-container" style={{ width: isMinWidth ? '47%' : '100%' }}>
                    <p>
                        <b>Comercial:</b> Nivelar el flujo de combustible y compensar los
                        desbalances en la relación oferta–demanda
                    </p>
                </div>
                <div className="p-container" style={{ width: isMinWidth ? '47%' : '100%' }}>
                    <p>
                        <b>Estratégico:</b> Ayuda a que México se haga menos susceptible a las
                        contingencias internas y externas.
                    </p>
                </div>
            </div>
            <div className="p-container">
                <p>
                    De acuerdo al Prontuario Mensual de Petrolíferos de septiembre de 2019, la
                    infraestructura de almacenamiento a nivel nacional contaba con un total de 83
                    terminales de almacenamiento, con una capacidad nominal de 28.8 millones de
                    barriles.
                </p>
            </div>
            <div className="p-container p-last">
                <p>
                    <b>
                        Actualmente el inventario mínimo aplicable a todo el periodo 2020-2025 es
                        de 5 días para gasolina y diésel.
                    </b>
                </p>
            </div>
        </>
    );
};

export default Petroil;
