import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { useQueryParam, StringParam } from 'use-query-params';

import Gas from './Gas';
import Hydro from './Hydro';
import Electric from './Electric';
import styles from './styles';
import Petroil from './Petroil';

const Worklines = () => {
    const [wk] = useQueryParam('wk', StringParam);
    const {
        gasImage, lightImage, oilImage, petrImage
    } = useStaticQuery(graphql`
        query WorklinesQuery {
            gasImage: file(relativePath: { eq: "home/gas.png" }) {
                childImageSharp {
                    fluid(quality: 80, maxHeight: 360) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            lightImage: file(relativePath: { eq: "home/electricity.png" }) {
                childImageSharp {
                    fluid(quality: 80, maxHeight: 360) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            oilImage: file(relativePath: { eq: "home/oil.png" }) {
                childImageSharp {
                    fluid(quality: 80, maxHeight: 360) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            petrImage: file(relativePath: { eq: "home/news-industry.png" }) {
                childImageSharp {
                    fluid(quality: 80, maxHeight: 360) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    const renderArticle = (wkParam) => {
        const arcs = {
            gas: <Gas />,
            electric: <Electric />,
            hydro: <Hydro />,
            petroil: <Petroil />
        };
        return wkParam ? arcs[wkParam] : <Hydro />;
    };
    return (
        <section css={styles} className="worklines">
            <div className="worklines-menu">
                <div>
                    <Link
                        to="/lineastrabajo?wk=hydro"
                        rel="nofollow noopener noreferrer"
                        className={wk === 'hydro' || wk === undefined ? 'workline-selected' : 'workline-img'}
                    >
                        <BackgroundImage
                            fluid={oilImage.childImageSharp.fluid}
                        >
                            <p>Hidrocarburos</p>
                        </BackgroundImage>
                    </Link>
                    <Link
                        to="/lineastrabajo?wk=petroil"
                        rel="nofollow noopener noreferrer"
                        className={wk === 'petroil' ? 'workline-selected' : 'workline-img'}
                    >
                        <BackgroundImage
                            fluid={petrImage.childImageSharp.fluid}
                        >
                            <p>Petrolíferos</p>
                        </BackgroundImage>
                    </Link>
                </div>
                <div>
                    <Link
                        to="/lineastrabajo?wk=gas"
                        rel="nofollow noopener noreferrer"
                        className={wk === 'gas' ? 'workline-selected' : 'workline-img'}
                    >
                        <BackgroundImage
                            fluid={gasImage.childImageSharp.fluid}
                        >
                            <p>Gas Natural</p>
                        </BackgroundImage>
                    </Link>
                    <Link
                        to="/lineastrabajo?wk=electric"
                        rel="nofollow noopener noreferrer"
                        className={wk === 'electric' ? 'workline-selected' : 'workline-img'}
                    >
                        <BackgroundImage
                            fluid={lightImage.childImageSharp.fluid}
                        >
                            <p>Electricidad</p>
                        </BackgroundImage>
                    </Link>
                </div>
            </div>
            <article>
                {renderArticle(wk)}
            </article>
        </section>
    );
};

export default Worklines;
