import React from 'react';
import Img from 'gatsby-image';
import { useMediaQuery } from 'react-responsive';
import { useStaticQuery, graphql } from 'gatsby';

const Electric = () => {
    const isMinWidth = useMediaQuery({ query: '(min-width: 768px)' });
    const { sourcesImage, chartImage } = useStaticQuery(graphql`
        query WorklineElectricQuery {
            sourcesImage: file(relativePath: { eq: "worklines/sources.png" }) {
                childImageSharp {
                    fixed(quality: 80, width: 800) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            chartImage: file(relativePath: { eq: "worklines/chart.png" }) {
                childImageSharp {
                    fixed(quality: 80, width: 800) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);
    return (
        <>
            <div className="icons-container">
                <img src="/images/worklines/electr-icons.png" alt="CCE - Iconos de electricidad" />
            </div>
            <div className="p-container">
                <p>
                    <b>
                        Impulsamos el desarrollo de infraestructura en generación, transmisión y
                        distribución de electricidad para:
                    </b>
                </p>
            </div>
            <div className="article-row p-last">
                <div className="p-container" style={{ width: isMinWidth ? '32%' : '100%' }}>
                    <p>Atender la creciente demanda y garantizar costos bajos y estables</p>
                </div>
                <div className="p-container" style={{ width: isMinWidth ? '32%' : '100%' }}>
                    <p>Fortalecer nuestra soberanía y seguridad energética</p>
                </div>
                <div className="p-container" style={{ width: isMinWidth ? '32%' : '100%' }}>
                    <p>Generar empleo y desarrollo económico regional</p>
                </div>
            </div>
            <div className="images-divider">
                <img style={{ width: '40%' }} src="/images/worklines/electr-imagen1.png" alt="CCE - electr divider" />
                <img style={{ width: isMinWidth ? '55px' : '30px' }} src="/images/worklines/gas-separator.png" alt="CCE - electr divider" />
                <img style={{ width: '40%' }} src="/images/worklines/electr-imagen2.png" alt="CCE - electr divider" />
            </div>
            <h2>
                La energía eléctrica es un insumo primario para la realización de las
                actividades productivas, de transformación y servicios en el país.
            </h2>
            <div className="article-row p-last">
                <div className="p-container" style={{ width: isMinWidth ? '32%' : '100%' }}>
                    <p>
                        Representa
                        <b> la segunda fuente de energía de mayor consumo en México,</b> con
                        una participación de 17.6% del consumo energético nacional. Fuente
                        PROSEDEN.
                    </p>
                </div>
                <div className="p-container" style={{ width: isMinWidth ? '32%' : '100%' }}>
                    <p>
                        En los próximos años, la demanda eléctrica nacional crecerá en promedio más
                        que la economía, y
                        <b> requiere una inversión de 891,000 mdp para 2024 y 2
                            billones a 2032.
                        </b>
                    </p>
                </div>
                <div className="p-container" style={{ width: isMinWidth ? '32%' : '100%' }}>
                    <p>
                        <b>
                            Para enfrentar este desafío la diversificación de las matrices
                            energéticas es fundamental,
                        </b> ya que esto permite aprovechar recursos disponibles localmente,
                        mitigar el riesgo de dependencia energética de otros países y
                        disminuir los costos de generación.
                    </p>
                </div>
            </div>
            <div className="images-divider">
                <img style={{ width: '100%' }} src="/images/worklines/electr-imagen3.png" alt="CCE - electr divider 2" />
            </div>
            <h3>El desarrollo de energías renovables ayudará a México a:</h3>
            <div className="article-row">
                <div className="p-container" style={{ width: isMinWidth ? '32%' : '100%' }}>
                    <p>
                        <b>Reducir los costos de generación</b>
                    </p>
                </div>
                <div className="p-container" style={{ width: isMinWidth ? '32%' : '100%' }}>
                    <p>
                        <b>Garantizar la sustentabilidad del sector</b> gracias a las mejoras en
                        tecnologías de los últimos años
                    </p>
                </div>
                <div className="p-container" style={{ width: isMinWidth ? '32%' : '100%' }}>
                    <p>
                        <b>Cumplir con los compromisos nacionales e internacionales</b> con
                        respecto a la emisión de gases de efecto invernadero.
                    </p>
                </div>
            </div>
            <h2>
                85% del territorio nacional tiene condiciones óptimas para la generación de
                energía limpia.
            </h2>
            <Img
                alt="Tipo de energias"
                objectFit="contain"
                className="workline-example"
                fixed={sourcesImage.childImageSharp.fixed}
            />
            <h2>Diversificación eléctrica en México</h2>
            <Img
                className="workline-example"
                objectFit="contain"
                alt="Diversificación eléctrica"
                fixed={chartImage.childImageSharp.fixed}
            />
            <div className="p-container p-last">
                <p>
                    <b>
                        En los últimos 30 años la inversión del sector privado ha contribuido a
                        construir un sistema eléctrico cada vez más eficiente.
                    </b>
                </p>
            </div>
        </>
    );
};

export default Electric;
